import React, { useState, useEffect } from 'react';
import logo from '../assets/szechenyi.png';

export default function CompetitionLogo(): JSX.Element {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  let isMobile: boolean = width <= 600;

  const logoStyle = {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 1000,
    width: isMobile ? '120px' : '160px',
  } as React.CSSProperties;

  return (
    <a href='/doc/szechenyi-terv.pdf' target='_blank'>
      <img src={logo} style={logoStyle} alt='széchenyi logó'></img>
    </a>
  );
}
