import { Box, Flex, useDisclosure } from "@chakra-ui/core";
import { css } from "@emotion/core";
import exitIntent from "exit-intent";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AuthWhatsOpen } from "../home/HomeConstants";
import { AuthModal, NavbarLoggedIn, NavbarLogin } from "./NavAuthComponents";
import { NavbarCart, NavbarContent, PopupModal } from "./NavComponents";
import {
  authConnector,
  openAuthModal,
  ReduxNavProps,
  useOpenPopupStore,
  usePopupStore,
} from "./NavConstants";

function Navbar(reduxProps: ReduxNavProps): JSX.Element {
  const authUseDisclosure = useDisclosure();
  const location = useLocation();
  const [authWhatsOpen, setAuthWhatsOpen] = useState(AuthWhatsOpen.login);

  const [, setPopupWhatsOpen] = usePopupStore();
  const [, setPopupOpen] = useOpenPopupStore();

  useEffect(() => {
    if (reduxProps.isLoggedIn && !reduxProps.userInfo) {
      reduxProps.AuthActions.getUserInfo();
    }
    return () => {};
  }, [
    reduxProps.isLoggedIn,
    reduxProps.cartItems,
    reduxProps.AuthActions,
    reduxProps.userInfo,
  ]);

  const removeExitIntent = exitIntent({
    threshold: 12,
    maxDisplays: 1,
    eventThrottle: 200,
    onExitIntent: () => {
      setPopupWhatsOpen("exit");
      setPopupOpen(true);
    },
  });

  // ///////////////////////
  // This disables the popup
  // ///////////////////////
  removeExitIntent();

  useEffect(() => {
    return () => {
      removeExitIntent();
    };
  }, [removeExitIntent]);

  useEffect(() => {
    if (location.pathname.split("/")[2] === "cart" && !reduxProps.isLoggedIn) {
      if (setAuthWhatsOpen) setAuthWhatsOpen(AuthWhatsOpen.login);
      openAuthModal(authUseDisclosure);
    }
  }, [location.pathname, reduxProps.isLoggedIn]);

  return (
    <>
      <AuthModal
        reduxProps={reduxProps}
        whatsOpen={authWhatsOpen}
        setwhatsOpen={setAuthWhatsOpen}
        disclosure={authUseDisclosure}
      />
      <PopupModal reduxProps={reduxProps} />
      <Box
        as="nav"
        position="relative"
        maxW={["100%", "540px", "720px", "960px", "1140px"]}
        w="100%"
        flexShrink={0}
        zIndex={300}
        pb={4}
        css={css`
          * {
            z-index: 300;
          }
        `}
      >
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          display={["none", "none", "none", "flex"]}
          py={2}
        >
          <NavbarLogin
            display={reduxProps.isLoggedIn ? "none" : "initial"}
            disclosure={authUseDisclosure}
            setwhatsOpen={setAuthWhatsOpen}
          />
          <NavbarLoggedIn
            display={reduxProps.isLoggedIn ? "initial" : "none"}
            disclosure={authUseDisclosure}
            setwhatsOpen={setAuthWhatsOpen}
          />
          <NavbarCart reduxProps={reduxProps} />
        </Flex>
        <NavbarContent
          setwhatsOpen={setAuthWhatsOpen}
          reduxProps={reduxProps}
          disclosure={authUseDisclosure}
        />
      </Box>
    </>
  );
}

export default authConnector(Navbar);
