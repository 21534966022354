import { Flex, Heading, Image, Link } from '@chakra-ui/core';
import React from 'react';
import { generatePath } from 'react-router-dom';

import kecskemetLogo from '../assets/kecskemetLogo.png';
import logo from '../assets/xmas_logo.png';
import szolnokLogo from '../assets/szolnokLogo.png';

function ChooseCityScreen(): JSX.Element {
  return (
    <>
      <Image
        mx='auto'
        height={['250px', '250px', '250px', '250px']}
        my={4}
        display={['inherit', 'inherit', 'none']}
        src={logo}
      />
      <Heading
        fontSize={['2xl', '4xl']}
        textAlign='center'
        p={8}
        pb={[4, 8, 16]}
      >
        Üdvözöl a Pizza Sprint.
        <br />
        Kérlek válassz várost.
      </Heading>

      <Flex
        justifyContent='space-around'
        alignItems='center'
        flexDir={['column', 'row']}
        color='#dddddd'
        mx='auto'
      >
        <Link
          href={generatePath('/:city', {
            city: 'szolnok',
          })}
          p={4}
          _hover={{ color: '#ffffff', textDecoration: 'none' }}
        >
          <Flex
            justifyContent='space-around'
            direction='column'
            alignItems='center'
          >
            <Image src={szolnokLogo} height='150px' />
            <Heading as='h3' size='lg' textAlign='center' p={4}>
              Szolnok
            </Heading>
          </Flex>
        </Link>

        <Image
          display={['none', 'none', 'inherit']}
          height='250px'
          src={logo}
        />

        <Link
          href={generatePath('/:city', {
            city: 'kecskemet',
          })}
          p={4}
          _hover={{ color: '#ffffff', textDecoration: 'none' }}
        >
          <Flex
            justifyContent='space-around'
            direction='column'
            alignItems='center'
          >
            <Image src={kecskemetLogo} height='150px' />
            <Heading as='h3' size='lg' textAlign='center' p={4}>
              Kecskemét
            </Heading>
          </Flex>
        </Link>
      </Flex>
    </>
  );
}

export default ChooseCityScreen;
